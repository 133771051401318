import React from 'react'

const ColorBotLogo = props => (
  <svg
    id="prefix__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 281 249"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.prefix__st3{fill:#80b6e0}'}</style>
    <g id="prefix__Layer_2">
      <path
        d="M106.9 37.2c0-18.4 15.2-33.5 33.6-33.3 18.2.2 32.9 15.1 33.1 33.5.2 18.2-14.9 33.4-33.1 33.3-18.9 0-33.6-14.7-33.6-33.5z"
        fill="#87ced1"
      />
      <path
        d="M241.1 208.7v-3.3-68.3c0-2.7.6-3 3.4-3 20 0 33.3 21.4 32.4 37.8-.8 15.9-11.5 29.7-26.9 34.2-2.8.8-5.6 1.6-8.9 2.6z"
        fill="#29b4ad"
      />
      <path
        d="M41 208.7v-3.3-68.3c0-2.7-.6-3-3.4-3-20 0-33.3 21.4-32.4 37.8.8 15.8 11.5 29.6 26.9 34.1 2.8.9 5.6 1.7 8.9 2.7z"
        opacity={0.86}
        fill="#8999cc"
      />
      <path d="M139.3 89.9v-6c0-4.2 0-8.4.1-12.6.2-6.7-2.2-10.6-8.3-13.5-9.3-4.3-14.3-13.6-12.6-23.7 1.6-10 9.3-17.6 19.3-18.9 11.8-1.6 22.1 5.3 25.3 17 2.9 10.7-3 22.1-13.7 26.5-5 2-6.8 4.9-6.8 10.2.1 6.1.1 12.3.1 18.4v2.6h2.6c18.2 0 36.4.1 54.6 0 16.8-.1 29.5 7.1 37.1 22.1 2.2 4.3 2.5 9.6 3.6 14.4.3 1.4.3 3 .5 4.5.7 4.3 3.2 6.8 7.4 8 9.9 3 17 9.2 21.1 18.7 7.4 17.4-2.2 37.9-20.3 43.3-5.4 1.6-8.1 4.9-8.8 10.5-2.2 17.7-18 32.4-35.8 33-15.3.5-30.7.3-46.1.3-26.1 0-52.3-.3-78.4 0-19.3.2-36.5-14.8-39-33.3-.1-.4-.1-.7-.2-1.1-.5-5.4-2.2-7.5-7.5-9-5.5-1.6-10.3-4.3-14.4-8.4-17.2-17.3-9.7-47 13.7-54 6.1-1.8 7.6-3.6 7.9-10 .6-10.6 4-20 11.7-27.7 7.7-7.6 17.1-11.2 27.8-11.3 18.7-.2 37.4-.1 56.2-.1.9.1 1.8.1 2.9.1zm1.3 151.4c21 0 42.1.2 63.1-.1 10.1-.2 18.8-4.6 25.4-12.3 6.1-7.1 8.7-15.5 8.8-24.8v-67.4c0-3.8-.1-7.6-.4-11.4-1-10-5.5-18.2-13.4-24.5-6.7-5.4-14.5-7.8-23.1-7.8H84.6c-2.8 0-5.7 0-8.5.4-12.1 1.6-21.5 7.5-27.6 18.3-3.4 6.2-4.5 12.9-4.5 19.8 0 23.2-.2 46.4-.1 69.6 0 4.2.4 8.5 1.3 12.7 3.2 15.4 17.6 27.2 33.4 27.3 20.7.3 41.3.2 62 .2zm100.6-41.5c2.5-.7 4.7-1.4 6.9-2 11.9-3.5 20.2-14.3 20.8-26.6.7-12.7-9.7-29.3-25.2-29.3-2.2 0-2.6.2-2.6 2.3.1 17.7 0 35.3 0 53 .1.7.1 1.4.1 2.6zM40.5 140.7c-20.3 2.6-29.2 18.1-27.1 33.4 2.2 15.2 14.2 24.7 27.1 24.1v-57.5zm81-103.3c0 10.9 8.6 19.6 19.6 19.6 10.6 0 19.4-8.9 19.3-19.5-.1-10.7-8.7-19.5-19.3-19.6-10.7 0-19.6 8.8-19.6 19.5z" />
      <path d="M89 167.2c-8.7 0-15.8-7.2-15.9-16.1 0-8.3 7.2-15.4 15.5-15.4 9.1 0 16 6.7 16 15.6.1 8.9-6.8 15.9-15.6 15.9zm12.4-15.8c0-7.3-5.6-12.6-13.2-12.5-6.2.1-11.9 6.2-11.8 12.6.1 6.8 5.8 12.5 12.4 12.5 7.1 0 12.6-5.5 12.6-12.6zM194.8 167.2c-8.7-.1-15.6-7.1-15.6-15.8 0-8.6 7.2-15.8 15.7-15.8 9 0 15.8 7.1 15.8 16.3 0 8.4-7.3 15.4-15.9 15.3zm12.7-15.8c0-6.9-5.7-12.6-12.5-12.6-6.9 0-12.5 5.6-12.6 12.5S188.1 164 195 164c6.8 0 12.5-5.8 12.5-12.6z" />
      <path
        className="prefix__st3"
        d="M101.4 151.4c0 7.1-5.5 12.6-12.6 12.6-6.7 0-12.3-5.7-12.4-12.5-.1-6.4 5.6-12.5 11.8-12.6 7.7-.1 13.3 5.2 13.2 12.5zM207.5 151.4c0 6.8-5.7 12.6-12.5 12.6-6.9 0-12.7-5.8-12.6-12.7.1-6.9 5.7-12.5 12.6-12.5 6.8 0 12.5 5.7 12.5 12.6z"
      />
    </g>
  </svg>
)

export default ColorBotLogo

