import React, { Component, createRef } from 'react'

import { navigate } from 'gatsby'

import styled from 'styled-components'

import Layout from '../../components/layout'

import { MainSectionWrapper } from '../../components/common/styles'

import AlpamamaLogo from '../../components/icons_and_logos/alpamama-logo'
import ColorBotLogo from '../../components/icons_and_logos/colobot-logo';

const MainSectionWrapperOverride = styled(MainSectionWrapper)`
    grid-row: 3 / 5;

    display: grid;
    place-items: center;

    padding: 0;
`

const PortfolioImageWrapper = styled.div`
    width: 27%;
    margin: 10px;
    height: 41%;

    max-height: 450px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    position: relative;

    font-weight: bold;

    cursor: pointer;
`

class PortfolioPage extends Component {
    scrollRef = createRef()

    componentDidMount = () => {
        window.addEventListener('keydown', this.scroll)
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.scroll)
    }

    scroll = (e) => {
        switch (e.key) {
            case "ArrowDown":
                this.scrollRef.current.scrollBy(0, 30)
                break
            case "ArrowUp":
                this.scrollRef.current.scrollBy(0, -30)
                break
        }
    }

    render() {
        return (
            <Layout>
                <MainSectionWrapperOverride ref={this.scrollRef}>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <PortfolioImageContainer
                            hoverColor='#08ffff'
                            onClick={() => navigate('/portfolio/colorbot')}
                        >
                            <ColorBotLogo />
                            <div>COLORBOT</div>
                        </PortfolioImageContainer>
                        <PortfolioImageContainer
                            style={{ background: '#fae8cc' }}
                            opacity={0.58}
                            hoverColor='#ea958d'
                            onClick={() => navigate('/portfolio/paloquemao')}
                        >
                            <img
                                src="https://s3.amazonaws.com/valentina-site/portfolio_images/paloquemaoheadercopy.jpg"
                                alt=""
                            />
                        </PortfolioImageContainer>
                        <PortfolioImageContainer
                            hoverColor='#0808ff'
                            opacity={0.65}
                            onClick={() => navigate('/portfolio/procolorbot')}
                        >
                            <img
                                src="https://s3.amazonaws.com/valentina-site/portfolio_images/headermarisa.jpg"
                                alt=""
                                style={{ maxHeight: '75%', maxWidth: '90%' }}
                            />
                            <div>Pro ColorBot Collection</div>
                        </PortfolioImageContainer>
                        <PortfolioImageContainer
                            hoverColor='#ffff08'
                            onClick={() => navigate('/portfolio/haiti')}
                        >
                            <img
                                src="https://s3.amazonaws.com/valentina-site/portfolio_images/haitiheader.jpg"
                                alt=""
                                style={{ maxHeight: '90%' }}
                            />
                            <div>HAITI</div>
                        </PortfolioImageContainer>
                        <PortfolioImageContainer
                            hoverColor='#ff8787'
                            opacity={0.47}
                            onClick={() => navigate('/portfolio/alpamama')}
                        >
                            <AlpamamaLogo />
                        </PortfolioImageContainer>
                        <PortfolioImageContainer
                            hoverColor='#0000ff'
                            onClick={() => navigate('/portfolio/luca')}
                        >
                            <img src="https://s3.amazonaws.com/valentina-site/portfolio_images/lucaw2.jpg" alt="" />
                        </PortfolioImageContainer>
                        <PortfolioImageContainer
                            hoverColor='#ff0000'
                            style={{
                                border: '15px solid #d43028',
                                maxHeight: '400px'
                            }}
                            onClick={() => navigate('/portfolio/aurelia')}
                        >
                            <img
                                src="https://s3.amazonaws.com/valentina-site/portfolio_images/aureliaheader.jpg"
                                alt=""
                                style={{ maxWidth: '90%', maxHeight: '80%' }}
                            />
                            <div>aaaaaureliaaaaa</div>
                        </PortfolioImageContainer>
                        <PortfolioImageContainer
                            hoverColor='#404040'
                            onClick={() => navigate('/portfolio/hugo_unite')}
                        >
                            <img src="https://s3.amazonaws.com/valentina-site/portfolio_images/headerunite-hugoboss.jpg" alt="" />
                            <div>Unite- for Hugo Boss</div>
                        </PortfolioImageContainer>
                        <PortfolioImageContainer
                            hoverColor='#fae571'
                            onClick={() => navigate('/portfolio/dot_haiti')}
                        >
                            <img src="https://s3.amazonaws.com/valentina-site/portfolio_images/header-dot.haiti.jpg" alt="" />
                            <div>D.O.T. HAITI</div>
                        </PortfolioImageContainer>
                        <PortfolioImageContainer
                            hoverColor='#3090b0'
                            onClick={() => navigate('/portfolio/wabi')}
                        >
                            <img src="https://s3.amazonaws.com/valentina-site/portfolio_images/wabiheader.jpg" alt="" />
                            <div>WABI</div>
                        </PortfolioImageContainer>
                        <PortfolioImageContainer
                            hoverColor='#48ddee'
                            opacity={0.38}
                            onClick={() => navigate('/portfolio/colorama')}
                        >
                            <img src="https://s3.amazonaws.com/valentina-site/portfolio_images/coloramaheader.png" alt="" />
                            <div>Colorama</div>
                        </PortfolioImageContainer>
                        <PortfolioImageContainer
                            hoverColor='#86e1c5'
                            style={{
                                maxHeight: '400px'
                            }}
                            onClick={() => navigate('/portfolio/fique')}
                        >
                            <img
                                src="https://s3.amazonaws.com/valentina-site/portfolio_images/salpicon.jpg"
                                alt=""
                                style={{ width: '90%' }}
                            />
                            <div>FIQUE</div>
                        </PortfolioImageContainer>
                    </div>
                </MainSectionWrapperOverride>
            </Layout>
        )
    }
}

const PortfolioImageContainer = (props) => (
    <PortfolioImageWrapper {...props}>
        <ContainerOverlay opacity={props.opacity} hoverColor={props.hoverColor} />
        {props.children}
    </PortfolioImageWrapper>
)

const ContainerOverlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;

    &:hover {
        background: ${props => props.hoverColor};
        opacity: ${props => props.opacity || 0.5} ;
    }
`

export default PortfolioPage