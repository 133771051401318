import React from 'react'

const AlpamamaLogo = props => (
  <svg
    id="prefix__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 137.9 133.8"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        '.prefix__st1{clip-path:url(#prefix__SVGID_4_)}.prefix__st2{clip-path:url(#prefix__SVGID_6_)}.prefix__st3{clip-path:url(#prefix__SVGID_8_)}.prefix__st4{clip-path:url(#prefix__SVGID_10_)}.prefix__st5{clip-path:url(#prefix__SVGID_12_)}.prefix__st6{clip-path:url(#prefix__SVGID_14_)}.prefix__st7{clip-path:url(#prefix__SVGID_16_)}.prefix__st8{clip-path:url(#prefix__SVGID_18_)}'
      }
    </style>
    <defs>
      <path id="prefix__SVGID_1_" d="M4.4 3.8h127.8v126.3H4.4z" />
    </defs>
    <clipPath id="prefix__SVGID_2_">
      <use xlinkHref="#prefix__SVGID_1_" overflow="visible" />
    </clipPath>
    <path
      d="M37.8 15.1L36.5 14c-.3-.2-.5-.5-.8-.7.1.3.2.6.3 1l.4 1.6 1.4-.8zm-1.3 1.3l.4 1.7-.6.4-1.2-5.5.7-.4 4.4 3.7-.6.4-1.4-1.2-1.7.9z"
      clipPath="url(#prefix__SVGID_2_)"
    />
    <path d="M40.7 9.9l.7-.2 2 4.3 2.1-.9.3.5-2.8 1.3z" />
    <defs>
      <path id="prefix__SVGID_3_" d="M4.4 3.8h127.8v126.3H4.4z" />
    </defs>
    <clipPath id="prefix__SVGID_4_">
      <use xlinkHref="#prefix__SVGID_3_" overflow="visible" />
    </clipPath>
    <path
      className="prefix__st1"
      d="M48.4 9.7c.2 0 .3-.1.6-.1.8-.3 1.2-.8.9-1.5-.2-.7-.8-.8-1.6-.6-.3.1-.5.2-.6.3l.7 1.9zm-1.5-2.2c.3-.2.7-.3 1.3-.5.7-.2 1.2-.2 1.6 0 .4.2.7.5.8.9.2.5.1.9 0 1.2-.2.5-.7.9-1.4 1.1-.2.1-.4.1-.6.1l.7 2.1-.7.2-1.7-5.1zM56.4 8.1l-.9-1.4c-.2-.3-.3-.6-.5-.9 0 .3 0 .7-.1 1l-.2 1.6 1.7-.3zm-1.6.9l-.2 1.8-.7.1.7-5.7.8-.2 3 4.9-.7.2-.9-1.5-2 .4zM70 6.8V4.5c-.2.6-.4 1.3-.7 2.1l-1 2.6h-.5l-.9-2.6c-.3-.8-.5-1.5-.6-2.1 0 .7-.1 1.6-.1 2.4L66 9.2h-.7l.4-5.4h.9l.9 2.6c.2.7.4 1.3.6 1.8.1-.5.3-1.1.6-1.8l1-2.6h.9l.4 5.4h-.7L70 6.8zM77.1 7.6L76.7 6c-.1-.4-.1-.7-.2-1-.1.3-.3.6-.4.9l-.7 1.5 1.7.2zm-1.9.3l-.8 1.6-.7-.1 2.6-5.1.8.1 1.1 5.6-.7-.1-.4-1.8-1.9-.2zM86.3 9.5c.2-.7.4-1.6.5-2.3-.3.6-.7 1.2-1.2 1.8L84 11.3l-.5-.1-.2-2.7c0-.8-.1-1.5-.1-2.2-.2.6-.5 1.5-.7 2.3l-.8 2.2-.6-.2 1.8-5.1.9.2.2 2.8c0 .7.1 1.3 0 1.9.3-.5.6-1 1-1.6l1.6-2.3.9.2-1 5.3-.7-.2.5-2.3zM92.9 12.1l.1-1.6c0-.4.1-.7.1-1-.2.3-.4.5-.6.8l-1.1 1.2 1.5.6zm-1.9-.2l-1.2 1.3-.7-.3L93 8.7l.8.3-.4 5.7-.7-.3.1-1.8-1.8-.7zM100.9 15.7c.2-.2.4-.3.7-.2.2.2.3.4.1.7-.2.2-.4.3-.7.2-.3-.1-.3-.4-.1-.7"
    />
    <path d="M110.3 19.3l-1.5 1.7 1.9 1.7 1.6-1.6.5.4-3.7 4-.5-.4 1.7-1.9-2-1.8-1.7 1.9-.5-.5 3.7-4z" />
    <defs>
      <path id="prefix__SVGID_5_" d="M4.4 3.8h127.8v126.3H4.4z" />
    </defs>
    <clipPath id="prefix__SVGID_6_">
      <use xlinkHref="#prefix__SVGID_5_" overflow="visible" />
    </clipPath>
    <path
      className="prefix__st2"
      d="M115 28.5l.8-1.4.6-.9c-.3.1-.6.3-.9.4l-1.6.6 1.1 1.3zm-1.6-.9l-1.7.7-.5-.5 5.4-2.1.6.6-2.9 4.9-.5-.6.9-1.5-1.3-1.5zM116.1 33.5l4.6-2.9.4.6-1.4 2.9c-.3.7-.6 1.2-1 1.8.6-.4 1.1-.8 1.9-1.3l1.9-1.2.4.6-4.6 2.9-.4-.6 1.4-2.9c.3-.6.6-1.3 1-1.8-.6.4-1.1.8-1.9 1.2l-2 1.2-.3-.5zM120.8 40.3c0 .2.2.4.3.7.7 1.3 1.8 1.7 3.1 1.1 1.2-.5 1.6-1.5.9-2.9-.2-.3-.3-.6-.4-.7l-3.9 1.8zm4.1-2.7c.2.4.5.8.8 1.3.4.9.5 1.6.4 2.3-.2.6-.6 1.1-1.4 1.5-.8.4-1.6.5-2.3.2-.7-.2-1.3-.9-1.8-1.8-.2-.4-.4-.8-.5-1.2l4.8-2.3zM127.9 54.5c.7-.2 1.6-.5 2.3-.6-.7 0-1.4-.1-2.2-.2l-2.8-.3-.1-.5 2.3-1.5c.7-.4 1.3-.8 1.9-1.1-.7.1-1.6.3-2.4.5l-2.3.4-.2-.6 5.4-.9.2.9-2.3 1.5c-.6.4-1.1.7-1.7 1 .6 0 1.2 0 1.9.1l2.8.3.2.9-5.2 1.6-.2-.7 2.4-.8zM128.8 61.4l1.5-.7c.3-.2.7-.3 1-.4-.3 0-.7-.1-1-.2l-1.6-.4.1 1.7zm-.8-1.7l-1.8-.4-.1-.7 5.6 1.3.1.8-5.3 2.4-.1-.7 1.7-.7-.1-2zM127.3 66.8c0 .2 0 .4-.1.7 0 1.5.8 2.3 2.3 2.4 1.3 0 2.1-.7 2.2-2.1 0-.4 0-.6-.1-.8l-4.3-.2zm4.9-.6c.1.4.1.9.1 1.5 0 1-.3 1.7-.7 2.2-.5.5-1.1.7-2 .7-.9 0-1.6-.3-2.1-.8s-.8-1.4-.7-2.4c0-.5 0-.9.1-1.3l5.3.1z"
    />
    <path d="M128.5 76.8l.3-2.1-2-.2-.3 2.3-.6-.1.4-3 5.5.7-.5 2.9h-.5l.3-2.2-1.7-.3-.3 2.1z" />
    <g>
      <defs>
        <path id="prefix__SVGID_7_" d="M4.4 3.8h127.8v126.3H4.4z" />
      </defs>
      <clipPath id="prefix__SVGID_8_">
        <use xlinkHref="#prefix__SVGID_7_" overflow="visible" />
      </clipPath>
      <path
        className="prefix__st3"
        d="M126.9 84.2c.3.1.4.3.3.6-.1.3-.3.4-.6.3-.3-.1-.4-.3-.3-.6.1-.2.3-.4.6-.3M122.2 91.4c-.3.2-.7.5-.9 1-.3.6-.2 1.2.3 1.4.5.2.9.1 1.4-.4.7-.7 1.3-.9 2-.6.8.4 1 1.3.6 2.2-.2.5-.5.8-.8 1l-.4-.4c.2-.1.5-.4.7-.8.3-.7.1-1.1-.3-1.3-.5-.2-.8 0-1.4.5-.7.7-1.3.9-2.1.5s-1.1-1.2-.6-2.4c.2-.5.6-.9.9-1.1l.6.4z"
      />
    </g>
    <path d="M123.1 99.5l-.4.5-4.1-2.5-1.3 1.9-.5-.3 1.6-2.5z" />
    <g>
      <defs>
        <path id="prefix__SVGID_9_" d="M4.4 3.8h127.8v126.3H4.4z" />
      </defs>
      <clipPath id="prefix__SVGID_10_">
        <use xlinkHref="#prefix__SVGID_9_" overflow="visible" />
      </clipPath>
      <path
        className="prefix__st4"
        d="M117.1 103.3c-.9-.7-2.1-.9-2.8 0-.7.9-.3 2 .7 2.8.8.7 2.1.9 2.8 0 .7-.9.2-2-.7-2.8m-2.6 3.4c-1.5-1.2-1.5-2.7-.7-3.7.9-1.1 2.4-1.2 3.7-.2 1.4 1.1 1.6 2.6.6 3.7-.9 1.1-2.4 1.2-3.6.2M109.5 107.8l4.7 3-.5.5-2.4-1.6c-.6-.4-1.2-.8-1.6-1.1.3.5.6 1 1 1.7l1.3 2.5-.5.5-2.4-1.6c-.6-.4-1.1-.7-1.6-1.1.3.5.6 1.1 1 1.7l1.3 2.5-.5.5-2.6-5 .5-.5 2.4 1.6c.6.4 1 .7 1.5 1.1-.3-.5-.6-.9-.9-1.6l-1.4-2.6.7-.5z"
      />
    </g>
    <path d="M102.8 120l-2.5 1.5-.3-.5 1.9-1.1-1-1.6-1.7 1.1-.3-.5 1.7-1.1-1.3-2.1.6-.3z" />
    <g>
      <defs>
        <path id="prefix__SVGID_11_" d="M4.4 3.8h127.8v126.3H4.4z" />
      </defs>
      <clipPath id="prefix__SVGID_12_">
        <use xlinkHref="#prefix__SVGID_11_" overflow="visible" />
      </clipPath>
      <path
        className="prefix__st5"
        d="M93.6 121.3l1.2 1.2c.3.3.5.5.7.8-.1-.3-.1-.7-.2-1l-.2-1.6-1.5.6zm1.4-1.2l-.2-1.8.7-.3.7 5.7-.8.4-4-4.1.7-.3 1.3 1.3 1.6-.9zM88.8 121.8c-.4-.1-.9-.1-1.3.1-.7.2-1 .7-.8 1.2.2.5.5.7 1.3.7.9 0 1.6.3 1.9 1 .3.8-.2 1.6-1.2 2-.5.2-1 .2-1.2.1v-.6c.2 0 .6.1 1.1-.1.7-.2.8-.7.7-1.1-.2-.5-.6-.6-1.4-.6-1 0-1.6-.3-1.8-1.1-.3-.8.1-1.7 1.3-2.1.5-.2 1.1-.2 1.4-.1v.6z"
      />
    </g>
    <path d="M81.8 123l.5 2.5-2.6.5-.5-2.4-.7.1 1.1 5.3.7-.2-.5-2.2 2.6-.5.5 2.2.7-.1-1.1-5.3zM74.3 124.3l.6 5.3.7-.1-.6-5.3z" />
    <g>
      <defs>
        <path id="prefix__SVGID_13_" d="M4.4 3.8h127.8v126.3H4.4z" />
      </defs>
      <clipPath id="prefix__SVGID_14_">
        <use xlinkHref="#prefix__SVGID_13_" overflow="visible" />
      </clipPath>
      <path
        className="prefix__st6"
        d="M70.4 127.2c0-1.2-.6-2.2-1.8-2.2-1.1 0-1.8 1-1.7 2.3 0 1.1.6 2.2 1.8 2.2 1.1 0 1.7-1.1 1.7-2.3m-4.3.1c0-1.9 1.1-2.8 2.5-2.9 1.4 0 2.5 1.1 2.5 2.7 0 1.7-1.1 2.8-2.5 2.8-1.5.1-2.5-1-2.5-2.6M62.9 124.3l-.7 5.4-.8-.1-1.4-2.9c-.3-.7-.6-1.3-.7-1.9 0 .7-.1 1.4-.2 2.2l-.3 2.2-.7-.1.7-5.4.7.1 1.4 2.9c.3.6.6 1.3.8 1.9 0-.7.1-1.3.2-2.2l.3-2.3.7.2zM50.9 124.8c-.1.3-.3.4-.6.3-.3-.1-.4-.3-.3-.6.1-.3.3-.4.6-.3.3 0 .4.3.3.6"
      />
    </g>
    <path d="M41.5 124.2l-2.6-1.3.2-.5 2.1 1 .8-1.7-1.9-.8.2-.6 1.9.9 1.1-2.2.6.3z" />
    <g>
      <defs>
        <path id="prefix__SVGID_15_" d="M4.4 3.8h127.8v126.3H4.4z" />
      </defs>
      <clipPath id="prefix__SVGID_16_">
        <use xlinkHref="#prefix__SVGID_15_" overflow="visible" />
      </clipPath>
      <path
        className="prefix__st7"
        d="M36.3 118.6l-.6-.4c-.6-.4-1.3-.3-1.6.2-.4.6-.1 1.1.5 1.5.3.2.5.3.6.3l1.1-1.6zm-.8 2.4c-.3-.1-.8-.4-1.2-.6-.6-.4-1-.8-1.1-1.2-.1-.3 0-.7.2-1.1.4-.6 1-.8 1.6-.6-.3-.3-.3-.8-.1-1.3.3-.7.4-1.2.4-1.5l.7.3c0 .2-.2.6-.4 1.3-.3.7-.2 1.1.4 1.5l.6.3 1.3-2 .6.4-3 4.5zM30.8 114.6c.7-.9.9-2.1.1-2.8-.9-.7-2-.3-2.8.6-.7.8-1 2.1-.1 2.8.9.8 2.1.4 2.8-.6m-3.3-2.6c1.2-1.4 2.7-1.5 3.8-.6 1.1.9 1.2 2.4.1 3.7-1.1 1.3-2.7 1.5-3.8.6-1.1-.9-1.1-2.4-.1-3.7M22.1 106c-.5.5-1.2 1.2-1.7 1.6.6-.3 1.3-.6 2-.9l2.6-1.1.4.4-1.3 2.4c-.4.7-.8 1.3-1.1 1.9.5-.4 1.2-1 1.9-1.5l1.8-1.5.5.5-4.3 3.4-.6-.7 1.3-2.4c.3-.6.7-1.2 1-1.6-.5.3-1.1.5-1.7.8l-2.6 1.1-.6-.7 3.8-3.9.5.5-1.9 1.7z"
      />
    </g>
    <path d="M15.3 96.8l1.1 1.8 1.7-1-1.2-2 .5-.3 1.6 2.6-4.7 2.8-1.5-2.5.5-.3 1.1 1.9 1.5-.9-1.1-1.8z" />
    <g>
      <defs>
        <path id="prefix__SVGID_17_" d="M4.4 3.8h127.8v126.3H4.4z" />
      </defs>
      <clipPath id="prefix__SVGID_18_">
        <use xlinkHref="#prefix__SVGID_17_" overflow="visible" />
      </clipPath>
      <path
        className="prefix__st8"
        d="M14.1 89.2c.2.2.6.6.8 1.2.6 1.4.2 2.8-1.4 3.6-1.6.7-3.1.1-3.8-1.4-.3-.6-.3-1.1-.3-1.3l.6-.1c0 .3.1.7.3 1.1.5 1.2 1.6 1.6 2.9 1 1.2-.6 1.7-1.6 1.1-2.8-.2-.4-.4-.7-.7-.9l.5-.4zM7.7 87.1l3.1-1c1.2-.4 1.5-1 1.3-1.7-.2-.8-.9-1.1-2.1-.7l-3.1 1-.2-.7 3-.9c1.6-.5 2.5.1 2.9 1.2.3 1.1 0 2-1.6 2.5l-3.1 1-.2-.7zM8.4 77.1l-1.4.8c-.3.2-.6.3-.9.5.3 0 .7 0 1 .1l1.7.2-.4-1.6zm.9 1.7l1.8.2.1.7-5.7-.8-.2-.8 5-2.8.1.7-1.6.9.5 1.9zM9.4 71.6v-.7c-.1-1.5-1-2.2-2.4-2.2-1.3.1-2.1.8-2 2.3 0 .4.1.6.1.8l4.3-.2zm-4.8 1c-.1-.4-.2-.9-.2-1.5-.1-1 .1-1.7.6-2.2.4-.5 1-.8 1.9-.9.9 0 1.6.2 2.2.7.5.4.8 1.3.9 2.3v1.3l-5.4.3zM7.2 64.1c1.2.1 2.3-.5 2.3-1.6.1-1.1-.9-1.8-2.1-1.9-1.1-.1-2.3.4-2.3 1.6-.2 1.2.9 1.8 2.1 1.9m.2-4.2c1.9.1 2.8 1.3 2.7 2.7-.1 1.4-1.3 2.3-3 2.2-1.7-.1-2.8-1.3-2.7-2.7.2-1.4 1.4-2.3 3-2.2M7.9 55.8l.1-.7c.1-.7-.2-1.3-.8-1.4-.7-.1-1.1.3-1.3 1 .1.3.1.6.1.7l1.9.4zm-2.5.2c0-.4.1-.9.2-1.3.1-.7.4-1.2.7-1.4.3-.3.7-.4 1.1-.3.7.1 1.1.7 1.2 1.3.2-.4.7-.5 1.2-.6.8 0 1.3 0 1.6-.1l-.1.7c-.2.1-.7.1-1.4.1-.8 0-1.1.2-1.3.9l-.1.6 2.3.5-.1.6-5.3-1zM10.8 46.1c-.3-.1-.4-.4-.3-.6.1-.3.4-.4.6-.3.3.1.4.4.3.6 0 .3-.3.4-.6.3M68.3 31c-1.1.3-2 .9-2.7 1.7-.5.6-1.2 1.9-.2 2.5.9.5 1.9-.3 2.5-.9.7-.7 1.7-1.7 1.4-2.8-.2-.6-1.1-.3-.9.2.3.8-2.3 3.1-2.6 2.5-.2-.4.8-1.3 1-1.5.5-.4 1.1-.7 1.8-.9.6-.1.3-1-.3-.8"
      />
      <path
        className="prefix__st8"
        d="M68.4 30.6c-.1.3-.2.5-.3.8-.2.5.6.8.8.4.3-.5.6-1 .9-1.4-.3-.1-.6-.2-.8-.4-.2.5-.3 1-.5 1.5-.1.4.3.7.7.5.3-.1.6-.3.9-.4l-.6-.6c-.1.2-.2.4-.4.6-.4.4.1 1 .6.7.3-.2.6-.3.9-.5l-.6-.6c-.1.2-.3.3-.6.4-.4.2-.2.9.2.9h.4c.6-.1.6-1 0-.9h-.4c.1.3.2.6.2.9.4-.2.6-.4.9-.7.3-.4-.3-.8-.6-.6-.3.2-.6.3-.9.5.2.2.4.5.6.7.2-.2.4-.5.6-.8.2-.4-.2-.8-.6-.6-.3.1-.6.3-.9.4.2.2.5.3.7.5.2-.5.3-1 .5-1.5s-.6-.8-.8-.4c-.3.5-.6 1-.9 1.4.3.1.6.2.8.4.1-.3.2-.5.3-.8.1-.7-.8-.9-1.1-.4M71.4 33.7c-1.2-1.5-3.8.9-4.6 1.9-1.1 1.3-.6 3.1 1.3 2.8 1.1-.2 2.1-.9 2.9-1.7.7-.6 1.7-1.5 1.7-2.4.1-1.5-2.2-1.7-2-.2 0 .5.6.6.9.2.4-.6.9-1.2 1.3-1.8-.3-.2-.5-.3-.8-.5-.2.5-.4.8-.7 1.2-.2.3.2.8.5.7.2 0 .5-.1.7-.1-.1-.3-.2-.5-.3-.8-.2.3-.4.4-.7.6.2.3.3.5.5.8.3-.1.5-.2.8-.3-.1-.3-.2-.5-.3-.8-.2.1-.4.3-.6.4-.5.1-.4 1 .1.9.6-.1 1.1 0 1.6.1 0-.3-.1-.6-.1-.8-.4.1-.7.1-1.1.2-.6-.1-.8.8-.2.9.7.1 1.2.1 1.8-.3.3-.2.3-.7-.1-.8-.7-.2-1.2-.2-1.9-.1 0 .3.1.6.1.9.5-.1.7-.3 1-.6.2-.3.1-.8-.3-.8-.5 0-.8.1-1.3.4-.5.3 0 1.1.5.8.4-.2.6-.4.9-.7.3-.3.1-.8-.3-.8-.5 0-.5 0-1 .2.2.2.3.4.5.7.3-.4.5-.8.7-1.2.2-.5-.4-.9-.8-.5-.4.6-.9 1.2-1.3 1.8.3.1.6.2.9.2.1.9-.9 1.6-1.5 2.1-.3.3-1.6 1.4-2.1 1.3-.2-.4-.5-.7-.7-1.1.1-.2.3-.3.4-.5.2-.2.4-.4.7-.6.4-.3 1.8-1.7 2.4-1 .3.4.9-.3.5-.7"
      />
      <path
        className="prefix__st8"
        d="M73.8 38.4c-.1-1.5-2.4-1.2-3.5-1.1-1.2.1-2.7.8-2.7 2.2 0 1.3 1.3 1.8 2.4 1.7 1.7-.1 3.1-1.1 3.9-2.5.1-.2.1-.4-.1-.6l-.3-.3c0 .2-.1.5-.1.7.4-.3.7-.5 1.1-.6-.2-.3-.3-.5-.5-.8-.2.2-.4.3-.6.5-.4.3-.2.9.4.8.4-.1.8-.2 1.2-.2 0-.2 0-.5-.1-.8-.4.2-.8.3-1.2.5-.4.1-.5.8 0 .9.2 0 .5.1.7.1-.1-.3-.1-.5-.2-.8-.1.1-.3.1-.4.2-.5.1-.4.8 0 .9.3.1.6.1.8.2 0-.3-.1-.6-.1-.8l-.9.3c-.6.1-.6 1 0 .9.5-.1.9-.2 1.4-.4.4-.2.3-.7-.1-.8-.3-.1-.6-.1-.8-.2v.9c.5-.1.5-.1.9-.4.3-.2.1-.7-.2-.8-.2 0-.5-.1-.7-.1v.9c.4-.2.8-.3 1.2-.5.5-.2.4-1-.2-.9-.4.1-.8.2-1.2.2.1.3.2.6.4.8.2-.2.4-.3.6-.5.5-.4.1-1-.5-.8-.4.2-.8.4-1.1.6-.2.2-.3.5-.1.7l.3.3c0-.2 0-.4-.1-.6-.6 1.1-2.3 2.5-3.7 2-.4 0-.5-.2-.4-.8-.4-.5-.3-.8.2-1 .4-.3.7-.3 1.1-.3.3 0 2.5-.2 2.5.2-.2.7.8.7.7.1M71.4 43.2c-1.4-.8-3-1-4.5-.4-.9.4-1.7 1.3-.7 2.1 1.1.8 4 .9 5.3.3.9-.4 1.1-1.5.2-2-.1.3-.2.6-.2.9h1.7c0-.3-.1-.6-.1-.9l-1.8.3c.1.3.2.6.2.9.6-.1 1-.1 1.5-.1v-.9c-.5 0-.8 0-1.3.3-.2.1-.3.3-.2.5 0 .1 0 .2.1.4.3-.1.5-.3.8-.4-.5-.4-.8-.4-1.3-.1-.3.1-.3.5-.1.7.4.4.3.6.7.9.5.3 1-.5.5-.8-.1-.1-.5-.7-.6-.7 0 .2-.1.5-.1.7h.2c.4.3.9 0 .8-.4 0-.1 0-.2-.1-.4-.1.2-.1.3-.2.5.3-.1.5-.1.8-.2.6 0 .6-.9 0-.9-.6-.1-1.2 0-1.8.1-.6.1-.3 1 .2.9l1.8-.3c.5-.1.4-.9-.1-.9-.6-.1-1.1-.2-1.7-.1-.4-.1-.6.6-.2.8-.2.5-.5.7-1.1.6h-1c-.6 0-1.7.1-2-.6-.3-.7 1.1-.7 1.7-.7.8 0 1.5.2 2.2.6.5.4.9-.4.4-.7M66.6 40.1c-.4-1.5-2.7.3-3.2.9-.9.8-1.8 2.7-.1 3.1 1.4.4 2.7-1.1 3.4-2.1.3-.5.6-1.1.2-1.6-.3-.4-.9-.6-1.4-.3.2.2.5.3.7.5l.1-.1c-.1-.3-.2-.6-.4-.8-.2.1-.4.2-.6.4-.5.3 0 1.1.5.8.2-.1.4-.2.6-.4.4-.3.2-1-.4-.8-.4.1-.7.4-.7.8-.1.3.3.8.7.5.2.3.1.5-.1.7l-.3.3-.5.5c-.4.4-.9 1.1-1.5.8-.8-.4.3-1.4.6-1.7.2-.1 1.6-1 1.6-1.2.1.5 1 .3.8-.3"
      />
      <path
        className="prefix__st8"
        d="M65.8 39c-.1.2-.1.4-.2.6-.1.3.1.6.4.6.5 0 .9-.2 1.3-.5-.2-.2-.4-.4-.7-.6-.1.1-.2.2-.4.3-.1.1-.2.4-.1.6 0-.1.1-.1.1-.2-.4.2-.4 1 .1.9h.5c-.1-.3-.2-.5-.3-.8-.1.1-.2.1-.3.2-.1.1-.1 0-.1.1-.1-.6-1-.6-.9 0 0 .4.3.8.8.8.5.1.9-.2 1.2-.5.3-.3.1-.8-.3-.8h-.5c0 .3.1.6.1.9.5-.2.7-.7.4-1.2 0 .2 0 .4-.1.6.1-.1.2-.2.4-.3.4-.4-.2-1-.7-.6-.2.2-.4.2-.7.2.1.2.3.4.4.6.1-.2.1-.4.2-.6.5-.6-.4-.9-.6-.3M63.6 35.1c-1.5.2-2.5 1.4-3 2.7-.5 1.2-.6 3.1 1.1 3.4 2.6.5 4.5-5.6 1.2-5.6.1.2.3.4.4.6.1-.2.1-.4.2-.6-.3 0-.6.1-.8.1.3.4.7.5 1.1.1-.3-.1-.5-.1-.8-.2.1.2.2.4.2.6.1.3.5.5.8.2.1-.1.2-.1.3-.2-.2-.1-.5-.2-.7-.3v.3c-.4.4.3 1.1.7.6.3-.4.4-.7.3-1.1-.1-.3-.4-.4-.7-.3-.3.1-.3.1-.5.4.3.1.5.1.8.2-.1-.2-.2-.4-.2-.6-.1-.3-.5-.5-.8-.2h.1c.1.1.3.2.4.2-.1-.1-.1-.1-.1-.3-.3-.3-.8-.2-.9.2-.1.2-.1.4-.2.6-.1.3.2.6.4.6 1.6 0 .5 2.4.1 2.9-.1.2-.4.7-.6.8-1.2.5-1.3-.8-1.1-1.5.3-1.3 1.2-2.5 2.6-2.7.5-.1.3-.9-.3-.9M66.1 28.3c-1.6-2.3-6 3.4-3.6 5.3 2.2 1.7 5.8-4.2 2.5-5.8.1.2.1.4.2.6.2-.3.3-.7.4-1h-.9c.1.4.1.7.2 1.1 0 .3.6.5.8.2.1-.1.2-.2.3-.4-.3-.1-.5-.3-.8-.4-.3 1 .9.9 1.4.3-.3-.2-.5-.3-.8-.5-.1.2-.2.3-.3.5-.2.4.3.9.6.6.1 0 .3-.1.4-.1-.1-.2-.3-.4-.4-.6-.1.2-.3.4-.4.6-.5.3 0 1.1.5.8.5-.3.7-.6.8-1.2.1-.3-.1-.5-.4-.6-.4 0-.6 0-.9.2l.6.6c.1-.2.2-.3.3-.5.3-.5-.5-.9-.8-.5.1.1.2.3.3.4.1-.4-.5-.8-.8-.4-.1.1-.2.2-.3.4.3.1.5.1.8.2-.1-.4-.1-.7-.2-1.1-.1-.5-.7-.4-.9 0-.1.3-.2.5-.3.8-.1.2-.1.5.2.6.8.4 1.1 1.3.9 2.1-.1.5-.4.9-.8 1.3-.4.5-.4.5-1.2.3-.8-.2-.8-.3-.6-1.1.1-.4.2-.7.4-1 .1-.2 1.5-2 1.9-1.5.5.7 1.3.2.9-.2"
      />
      <path
        className="prefix__st8"
        d="M61.1 30c.1-.4-.1-.9-.6-1-.7-.1-1.3.7-1.6 1.2-.9 1.5-1.4 4.5.1 5.9 1.6 1.4 2.9-1.7 3-2.8.1-.9.3-2 0-2.9-.1-.3-.3-.7-.6-.9-.2-.2-1.4-.6-1.4-.9-.3.1-.5.1-.8.2.3.4.7.8 1 1.2.2.2.6.2.7-.1.2-.5.3-.6.3-1.1-.3.1-.6.2-.9.2.1.2.2.4.3.5.2.5.8.2.9-.2 0-.2 0-.3.1-.5.1-.5-.8-.6-.9-.1-.1.3-.1.4 0 .8 0 .3.6.5.8.2.2-.2.3-.4.5-.6-.3-.1-.5-.2-.8-.3 0 .6 0 .5.2 1.1.2.6 1.1.3.9-.2 0-.3-.1-.5-.1-.8 0-.4-.5-.6-.8-.3-.2.2-.3.4-.5.6.3.1.5.1.8.2v-.3c-.3 0-.6-.1-.9-.1 0 .2 0 .3-.1.5.3-.1.6-.2.9-.2-.1-.2-.2-.4-.3-.5-.2-.4-.8-.2-.9.2-.1.2-.1.4-.2.7.2 0 .5-.1.7-.1-.3-.4-.7-.8-1-1.2-.2-.3-.7-.1-.8.2-.1.6.1.8.5 1.3.2.1.4.2.6.2.7.4 1 .9 1 1.6 0 .5 0 1-.1 1.5-.1.6-.2 1.2-.5 1.8-.2.5-.6.7-1.2.6-.2-.4-.3-.7-.4-1.1-.2-.8-.1-1.6.1-2.3.2-.7.8-1.3 1-2-.1.2.8.4 1-.2"
      />
      <path
        className="prefix__st8"
        d="M60.2 36.4l-.3-.3c-.2-.4-.7-.2-.8.1-1 3.3 0 6.5 1.4 9.6.2.3.8.3.8-.1.3-1.9.5-3.8.6-5.7 0-.6-.9-.6-.9 0-.1 1.8-.3 3.6-.5 5.4.3 0 .6-.1.8-.1-1.3-2.8-2.2-5.8-1.3-8.9-.3 0-.6.1-.8.1.2.4.2.4.6.6.5.4.9-.4.4-.7M61.7 35.5c0 .1.1.2.1.4.1.4.6.4.8.1.5-.8.8-1.7.9-2.6.1-.6-.9-.6-.9 0-.1.8-.3 1.5-.8 2.2.3 0 .6.1.8.1 0-.1-.1-.2-.1-.4 0-.6-.9-.3-.8.2"
      />
      <path
        className="prefix__st8"
        d="M65.7 35.4l.6-.9c-.3-.2-.5-.3-.8-.5-2.1 3.4-3.2 7.3-5.2 10.8-.3.5.3.9.7.6 1.2-1 1.6-2.5 2.6-3.7 1.1-1.4 2.5-2.6 3.8-3.8.3-.3 0-.9-.5-.8-.1 0-.3.1-.4.1-.6.2-.3 1.1.2.9.1 0 .3-.1.4-.1-.2-.3-.3-.5-.5-.8-1.3 1.1-2.6 2.3-3.7 3.6-1 1.2-1.5 2.9-2.7 3.9.2.2.5.4.7.6 1.9-3.5 3.1-7.4 5.2-10.8.3-.5-.5-.9-.8-.5l-.6.9c-.2.5.6 1 1 .5"
      />
      <path
        className="prefix__st8"
        d="M61.2 45.7c1-1.2 3.6-1.2 5-1.4.6-.1.6-1 0-.9-1.7.2-4.4.2-5.6 1.6-.4.5.2 1.2.6.7M66.8 40.9c.5-.5 1.1-.8 1.8-.7.6.1.6-.9 0-.9-.9-.1-1.9.3-2.5 1-.3.4.3 1.1.7.6M57 36.1c-2.6-.8-2.6 5-.2 5.3 1.4.2 1.9-1.4 1.7-2.5-.1-.7-.4-1.5-.8-2-.5-.6-1.4-1-1.7-1.8-.2.2-.5.3-.7.5.4.3.8.6 1.1.9.2.1.5 0 .6-.2.2-.4.3-.6.3-1-.2.1-.5.3-.7.4.3.1.5.2.8 0-.2-.2-.5-.3-.7-.5-.3.8.6 1.2 1.1.7-.2-.2-.5-.4-.7-.6 0 .2-.1.5-.1.8-.1.7.9.7.9.1.1-.2.1-.4.2-.6.3-.4-.3-.9-.7-.6.1.1.3.1.4.2.1-.4-.4-.7-.7-.5-.1 0-.1.1-.2.1h.5c-.1 0-.1-.1-.2-.1-.3-.1-.7 0-.7.4 0 .2-.1.4-.1.6.2-.1.4-.1.6-.2-.4-.3-.8-.6-1.1-.9-.3-.3-.8.2-.7.5.3 1.2 1.6 1.5 2.1 2.6.2.4.4 1 .4 1.4 0 .8-.8 1.6-1.4.6-.3-.4-.8-3.3.4-2.9.6.4.9-.5.3-.7"
      />
      <path
        className="prefix__st8"
        d="M58.2 41.2c-.1-.3-.1-.4-.3-.6-.2.2-.5.4-.7.6.8.9 1.6 1.8 2.3 2.8.4.5.8 1.7 1.5 1.9.3.1.5-.1.6-.3v-.1c.1-.2 0-.3-.1-.4l-.1-.1c-.1-.2-.4-.2-.6-.1-.5.3 0 1.1.5.8-.2 0-.4 0-.6-.1l.1.1c0-.1-.1-.3-.1-.4v.1l.6-.3c-.2 0-.6-1-.8-1.2l-.9-1.2-1.8-2.1c-.4-.4-1 .1-.7.6.1.1.1.3.2.4.2.4 1.1.2.9-.4"
      />
      <path
        className="prefix__st8"
        d="M60.4 45.4C57.1 54 55.5 62.8 55.5 72h.9c0-.4-.1-.7-.1-1.1-.1-.6-1-.6-.9 0 0 .4.1.7.1 1.1.1.6.9.6.9 0 0-9.1 1.6-17.9 4.9-26.3.2-.6-.7-.9-.9-.3"
      />
      <path
        className="prefix__st8"
        d="M56.6 64.5c4.2-4.7 9.8-7.4 15.9-8.9 2.2-.6 3.4-1 5.1-2.1.3-.2 5-2.7 4.9-2-.2 1.4-4.9 1.9-6.2 2-.6 0-.6 1 0 .9 3.5-.2 6.4-1.3 9.1-3.5.3-.2.1-.8-.3-.8-2.5.2-4.9.7-7.1 2-1.3.7-2.3 1.8-3.8 2.3-1.5.5-3.1.7-4.6 1.2-5.3 1.7-10 4.3-13.7 8.4-.4.3.3 1 .7.5"
      />
      <path
        className="prefix__st8"
        d="M71.4 55.8c1.7.2 3.1.6 4.6 1.4.2.1.4.1.6-.1 1.4-1.3 3.2-1.2 5-1-.1-.3-.2-.6-.2-.9-1.6.9-3.2 1.2-5.1 1-.5-.1-.9.6-.4.8 1.1.5 2.1 1 3.2 1.5.6.3 3.2 1.3 3.3 2 .2-.1.4-.3.6-.4-2.5-.4-5.4-1.4-6.6-3.8-.2-.5-1-.1-.8.4.5 1.5.9 3 .3 4.5.3 0 .6-.1.8-.1-.4-.6-.6-1.3-.7-2-.2-.7-.3-1.4-.3-2.1v-.6l-.1.1c-.4.5.3 1.1.7.6l.1-.1c.2-.2.2-.4 0-.6 0 0 0-.1-.1-.1-.1-.1-.4-.2-.6-.1-1.7 1.2-.5 4 .2 5.4.2.4.7.2.8-.1.7-1.7.3-3.3-.3-5-.3.1-.6.2-.8.4 1.3 2.6 4.4 3.8 7.2 4.2.3 0 .6-.1.6-.4-.1-.9-.6-1.1-1.4-1.5-1.8-1-3.6-1.9-5.5-2.7-.1.3-.2.6-.4.8 2.1.3 3.9 0 5.8-1.1.4-.2.2-.8-.2-.9-1-.1-1.9-.2-2.9-.1-.8.1-1.5.7-2.2.8-.8.1-1.4-.3-2.1-.5-1-.3-2-.5-3-.6-.7 0-.6.9-.1.9M61.6 59.9c1.5.4 3.1 1 4.7.8 2.1-.3 3.6-1.4 5.9-1.2-.1-.3-.2-.6-.2-.9-1.9.9-3.7 1.2-5.8 1-.5-.1-.6.6-.2.9 3.3 1.7 7.2 1.4 10.3 3.4.1-.3.2-.6.4-.8-.8-.3-.8-.2-1.7-.2-2.6-.1-2.8-.2-4.9-1.7-1.3-.9-2.2-1.3-3.7-1-.4.1-.5.7-.1.8 2 .8 2.9 3.9 2.9 5.7.2-.1.5-.3.7-.4-2.1-1.4-3-3.4-2.9-5.9 0-.6-.9-.6-.9 0-.2 2.8 1 5.1 3.4 6.7.3.2.7-.1.7-.4-.1-2.3-1.1-5.5-3.4-6.5 0 .3-.1.6-.1.8 2.3-.4 3.4 1.6 5.2 2.4 1.6.7 3-.1 4.6.4.5.2.8-.6.4-.8-3.1-2-7-1.7-10.3-3.4-.1.3-.2.6-.2.9 2.2.2 4.2-.1 6.2-1.1.4-.2.2-.8-.2-.9-1.8-.1-3.2.2-4.8.7-2.1.7-3.5.3-5.5-.3-.8-.1-1 .8-.5 1"
      />
      <path
        className="prefix__st8"
        d="M70.5 55.8c.6-2.6 2.5-4.7 5.1-5.5l-.6-.6c-.3 1.2-1.2 2.4-2 3.4-.4.4-.8.8-1.2 1.1-.3.2-1.6 1.1-1.9 1 .1.2.1.3.2.5l-.2.2c-.2-.1-.3-.1-.5-.2.3.5 1.1 0 .8-.5-.1-.2-.3-.3-.5-.2-.2.1-.4.2-.4.4s0 .4.2.5c1.1.6 2.9-1 3.6-1.6 1.2-1.1 2.5-2.8 2.8-4.4.1-.3-.2-.7-.6-.6-2.9.9-5.1 3.2-5.7 6.2-.1.6.8.9.9.3M60.2 60.8c4.1-2.4 3.4-8.3 8.4-9.7-.2-.2-.3-.4-.5-.7-1.1 2.3-2.7 4.2-4.8 5.8.2.2.4.5.6.7.8-.7 1.8-1 2.9-1-.1-.3-.2-.5-.3-.8-1.2 1.4-2.5 2.4-4.3 3l.6.6c.7-2.2 0-4.2-.9-6.3-.2-.4-.9-.4-.9.1.1 2-.2 4.3.9 6.1.3.5 1.1 0 .8-.5-1-1.6-.7-3.8-.8-5.6-.3 0-.6.1-.9.1.8 1.9 1.5 3.7.9 5.8-.1.3.2.7.6.6 1.9-.7 3.4-1.7 4.7-3.2.3-.3.1-.8-.3-.8-1.4 0-2.5.4-3.5 1.2-.4.3.1 1 .6.7 2.2-1.6 4-3.6 5.2-6.1.2-.3-.1-.8-.5-.7-1.8.5-3.2 1.5-4.2 3.1-1.5 2.3-1.8 5.2-4.4 6.7-.8.4-.4 1.2.1.9M56.6 68.7c.2-.5.3-1 .6-1.4.3-.5.8-.9 1.3-1.2.9-.6 1.9-1.1 2.9-1.7l-.6-.6c-1 2-2.6 3.5-4.6 4.3.1.2.3.5.4.6.1 0 .2-.1.3-.2.4-.4-.2-1.1-.7-.6l-.3.3c-.4.4.1.9.6.7 2.2-.9 3.9-2.6 5-4.7.2-.4-.2-.9-.6-.6-1.1.7-2.3 1.3-3.4 2-1 .8-1.3 1.5-1.7 2.6-.2.6.6 1 .8.5M56.4 51.2c.1-1.7.8-3 2.1-4-.3-.1-.5-.2-.8-.3-.2 1.8-.9 3.2-2.1 4.6.3.1.5.2.8.3v-.4c0-.5-.8-.6-.9-.1-.3 1.4-.6 2.9-.9 4.3-.2.8-.6 1.7-.4 2.5.1.4.5.4.8.2 0 0 .1 0 .1-.1.2-.2.1-.5 0-.6l-.1-.1c-.4-.4-1 .2-.7.6l.1.1v-.6s-.1 0-.1.1c.3.1.5.1.8.2-.2-.8.4-2.3.6-3.1.2-1 .5-2.1.7-3.1-.3 0-.6-.1-.9-.1v.4c0 .4.5.6.8.3 1.4-1.5 2.1-3.2 2.3-5.2.1-.4-.5-.5-.8-.3-1.5 1.2-2.2 2.8-2.3 4.7-.1.3.9.3.9-.3"
      />
      <path
        className="prefix__st8"
        d="M55.5 53.8c-.5-1.5-1-3.1-1.7-4.5-.3-.6-1-1-1.5-.2-.5.9.4 2.5.7 3.4.3.8.7 2.2 1.7 2.3.6.1.8-.8.2-.9-.8-.1-1.1-1.7-1.3-2.3l-.6-1.8c-.2.3.5 1.6.6 2 .3.8.6 1.5.8 2.3.4.4 1.3.2 1.1-.3M51.9 45.3c-.5-2.4.4-4.6 2.3-6.1-.2-.2-.5-.3-.7-.5-.3.9-.3 1.8-.5 2.7-.2 1.3-.8 2.3-1.4 3.5-.9 1.6-.6 3.2-1.3 4.9-.2.4.5.8.8.4.1-.1.2-.3.3-.4.4-.5-.3-1.1-.7-.6-.1.1-.2.3-.3.4.3.1.5.3.8.4.3-.7.3-1.4.4-2.2.2-1.4.8-2.4 1.5-3.6.9-1.7.8-3.5 1.3-5.3.1-.4-.4-.8-.7-.5-2.3 1.7-3.3 4.3-2.7 7.1.1.6 1 .4.9-.2"
      />
      <path
        className="prefix__st8"
        d="M52.1 47.1c.4-1.3 2.9-1.9 4-2.4l-.6-.6c-.4.7-.9 1.3-1.5 1.9-.3.3-.6.5-1 .8-.2.1-1 .8-1.1.8-.1-.3-.2-.6-.2-.9-.2.1-.4.3-.5.4.2.1.5.2.7.3-.4-1.4-1-2.6-1.9-3.7-.3-.3-.9-.3-.9.2.1 1.7.8 3.1 2 4.2.4.4 1.1-.3.7-.6-1.1-.9-1.7-2.1-1.8-3.5-.3.1-.6.2-.9.2.8 1.1 1.4 2.2 1.8 3.5.1.3.4.4.7.3.2-.1.4-.3.5-.4.4-.3.2-.8-.2-.9-1.3-.2-1.4 1.6-.2 1.7.8 0 1.7-.7 2.3-1.2.9-.7 1.7-1.6 2.3-2.6.3-.4-.2-.8-.6-.6-1.5.7-3.9 1.2-4.4 2.9-.3.5.6.8.8.2"
      />
      <path
        className="prefix__st8"
        d="M47.8 44c-.2-.4-2.3-6.4-.3-6.3.3 0 .4 3.7.4 4.3.1 2 0 2.4.7 4 2.3 5.1 4.9 10.2 7.2 15.4.2.5 1 .1.8-.5-2.2-4.8-4.3-9.6-6.5-14.3-1.6-3.4-1.2-7-2.1-10.6-.1-.4-.6-.4-.8-.1-1.9 2.8-1.9 5.7-.1 8.5.2.6 1 .1.7-.4"
      />
      <path
        className="prefix__st8"
        d="M46.4 50.5c-.4-1.2-3.3-5.1-4.7-3-1.4 2 3.3 4.8 4.9 4.7.2 0 .4-.1.4-.3v-.2c.1-.2 0-.4-.2-.5l-.1-.1c-.4-.3-1 .4-.6.7 1 .8 1.9 1.1 3.2 1.1.4 0 .6-.5.3-.8-1.2-1-1.8-2.2-2-3.8-.2.1-.5.3-.7.4.8.4 1.3.9 1.8 1.7.2.4.4 1.4.7 1.6-.4-.3-1 .4-.6.7.3.2 4 3.5 4.1 3.3-.4.5.4.9.8.5l.1-.1c.1-.1.1-.3 0-.5-.7-1.4-3.1-2.8-4.3-3.9-.2.2-.4.5-.6.7.1.1.2.1.3.2.1.1.3.1.5 0 1.9-1.2-1.1-4.5-2.4-5.1-.3-.1-.7 0-.7.4.2 1.8.9 3.2 2.3 4.5.1-.3.2-.5.3-.8-1 .1-1.8-.2-2.5-.9-.2.2-.4.5-.6.7-.4-.6-.9-1-1.6-1.2-.5-.3-1-.7-1.4-1.1 0-1.5.4-1.7 1.2-.7.5.5.9 1.1 1.1 1.8.3.8 1.2.6 1 0"
      />
      <path
        className="prefix__st8"
        d="M46 51.4c.1-.1.3 0 .3.1 0 .6 1 .6.9 0 0-.8-1-1.4-1.7-.9-.5.4-.1 1.2.5.8M48.9 53.2c-1.4-.5-2.8-.4-4 .4-.3.2-.3.7.1.8 2.3.8 4.7.8 6.9.1.5-.1.4-.7 0-.9-1.3-.5-2.6-.7-4.1-.6-.6.1-.6 1 0 .9 1.3-.2 2.6 0 3.8.5v-.9c-2.2.7-4.3.7-6.5-.1 0 .3.1.6.1.8 1-.7 2.1-.8 3.3-.3.8.4 1-.5.4-.7M48.4 59c2.4-.4 4.7.1 6.8 1.5v-.8c-2 .9-5.1 0-6.8-1.1-.1.3-.2.6-.4.8.2.1.3.1.5.2.6.2.8-.7.2-.9-.2-.1-.3-.1-.5-.2-.5-.2-.8.6-.4.8 2.1 1.4 5.4 2.2 7.7 1.1.3-.2.3-.6 0-.8-2.2-1.5-4.8-2-7.5-1.6-.4.2-.2 1.1.4 1M51.1 63.2c.8.7 1.3 1.6 2.1 2.4.7.7 1.5 1.2 2.4 1.6.5.3.9-.4.5-.8l-.9-.6c-.5-.3-.9.4-.5.8l.9.6c.2-.3.3-.5.5-.8-.9-.4-1.7-1-2.5-1.7-.7-.7-1.2-1.5-1.9-2.2-.4-.3-1 .3-.6.7"
      />
      <path
        className="prefix__st8"
        d="M51.5 63.3c1.1 0 2 .2 2.9.9.7.6 1.1 1.5 1.2 2.4 0 .6 1 .6.9 0-.1-1.2-.6-2.3-1.5-3-1-.9-2.2-1.1-3.5-1.2-.6 0-.6.9 0 .9M78.6 65.2c-1.8 1.7-4 2.8-6 4.2-.6.4 0 1.3.6.9 2.1-1.4 4.4-2.6 6.3-4.4.4-.4-.3-1.2-.9-.7M67.8 72.4c2.7-4.2 7.6-6.9 11.5-9.8.6-.4 0-1.4-.6-.9-4.1 3-9.1 5.9-11.9 10.2-.3.6.6 1.1 1 .5M73.9 73.3c-1.4.6-2.8 1.3-4.1 2.2-.6.4 0 1.4.6.9 1.2-.8 2.5-1.5 3.8-2.1.7-.3.4-1.3-.3-1M90.5 66c-3.6 4-10.1 5-15 6.7-.7.2-.4 1.3.3 1.1 5.1-1.8 11.8-2.7 15.5-7 .4-.5-.4-1.3-.8-.8"
      />
      <path
        className="prefix__st8"
        d="M74.9 70.4c2-.8 4-1.5 5.9-2.4.6-.3.4-1.3-.3-1.1-2 .8-3.9 1.6-5.9 2.4-.7.3-.4 1.4.3 1.1M82 71.9c3.5-.2 7.3.2 10.8.8.6 0 1.1 0 1.7-.1-.8 1.5-2 2.3-3.6 2.4-2 .5-4 .1-6 0-5.5-.2-10.2 1.4-14.4 4.9-.5.5.2 1.2.8.8 3-2.5 6.6-4.2 10.6-4.5 3-.2 6.1.5 9 0 2.4-.4 5.2-1.7 5.4-4.3 0-.4-.4-.6-.7-.5-4.6.8-9-.7-13.6-.5-.7 0-.7 1.1 0 1M70.5 85.4c-.2.4-.5.8-.7 1.2-.1.2-.1.6.2.7 2.9 1.5 5.4 1.1 8.5.5 1-.2 10.3-2.8 9 .7-.3.8-4.4 1.5-5.4 2-3.3 1.6-5.5 4.4-8.3 6.7-1.9 1.6-3.9 3-6.1 4.1-1.8.9-3.5 1.1-4.8 2.5-.4.4.1 1.2.7.9.5-.3 1.2-.8 1.5-1.3.3-.6-.4-1-.9-.7-.4.3-.9.7-1.3 1.1-.6.5.2 1.2.8.8.1-.1.2-.1.3-.2.6-.4-.2-1.2-.8-.8-.1.1-.2.1-.3.2l.8.8c.4-.4.9-.7 1.3-1.1-.3-.2-.6-.4-.9-.7-.2.3-.9.7-1.1.9.2.3.4.6.7.9 1.6-1.7 5-2.4 7-3.7 1.8-1.1 3.4-2.4 5-3.8 2-1.8 4-4 6.4-5.4 2.3-1.3 4.8-1.4 7-2.9.2-.1.3-.4.3-.6-.7-1.8-2-2.8-4-2.8-1.6 0-3.3.7-4.8 1-.7.1-10.4 1.8-9-.4.2-.6-.8-1.2-1.1-.6"
      />
      <path
        className="prefix__st8"
        d="M74.2 78.6c5.4-2.5 9.2 1.5 13.5 4.1.8.4 1.5.9 2.3 1.4.4 1.7 0 2.2-1.2 1.4-1.6 0-4-1.9-5.5-2.5-4.6-2-8.9-1.5-12.5 2.1-.5.5.3 1.3.8.8 5.1-5.2 10.1-2 15.6.2 1.8.7 4.3 1.8 5.7 0 .2-.2.2-.4.1-.7-1.6-3.1-6.9-4.9-9.7-6.9-3.4-2.4-5.7-2.6-9.6-.8-.7.3-.1 1.2.5.9M90.8 65.6c-.4.5-.8.9-1.4 1.2-.6.4-.1 1.3.6.9.6-.4 1.1-.8 1.6-1.4.5-.5-.3-1.2-.8-.7M81 68.1c3.3-1.1 6.7-2.1 10-3.2.7-.2.4-1.3-.3-1.1-3.3 1.1-6.7 2.1-10 3.2-.7.2-.4 1.3.3 1.1"
      />
      <path
        className="prefix__st8"
        d="M82.9 85.3c3.3 1.8 3 4.1 3.7 7.2.4 1.8 1.5 3.7 2.7 5.1 2.2 2.5 5.4 3 8.3 1.5.6-.3.1-1.3-.6-.9-6 3.2-9-3.8-9.6-8.3-.4-2.5-1.8-4.3-4-5.5-.6-.4-1.1.5-.5.9M79.8 84.2c-2.9.3-5.7 1-8.6.8-.7-.1-.7 1 0 1.1 2.9.2 5.7-.5 8.6-.8.7-.1.7-1.2 0-1.1M79.4 62.4c.3-.3.6-.5 1-.7.2-.1.4-.1.5-.2h-.1c-.1-.1-.1-.1 0 0v.1c0 .7 1.1.7 1.1 0 0-.9-.6-1.3-1.5-1.1-.7.2-1.3.7-1.8 1.1-.5.5.3 1.3.8.8M71.7 67.7l.8.8c.5.5 1.3-.3.8-.8l-.8-.8c-.5-.4-1.3.3-.8.8"
      />
      <path
        className="prefix__st8"
        d="M71.3 68.4c.3.2.5.4.8.5.6.4 1.1-.5.6-.9-.3-.2-.5-.4-.8-.5-.7-.5-1.2.5-.6.9M79.9 67.8c.2.6.5 1.1.9 1.6.5.5 1.2-.2.8-.8-.3-.3-.5-.7-.6-1.1-.2-.6-1.3-.4-1.1.3"
      />
      <path
        className="prefix__st8"
        d="M80.7 67.6c.1.4.3.8.5 1.2.2.3.4.7.8.8.3 0 .6-.1.7-.4.1-.2 0-.4-.1-.5-.2-.3-.5-.3-.8-.2-.6.3-.1 1.2.6.9-.3-.1-.5-.1-.8-.2 0-.1 0-.3-.1-.4.2-.1.5-.3.7-.4.2 0 0 0 0-.1-.1-.1-.1-.2-.2-.3-.1-.2-.2-.5-.3-.7-.2-.6-1.3-.4-1 .3M87.7 65.1c-.4.9.1 1.7 1 2 1 .2 1.8-.4 2.6-.9.6-.3.1-1.3-.6-.9-.4.2-.7.4-1.1.6-.2.1-1.3.4-1.1-.2.4-.7-.5-1.3-.8-.6M91.7 72.3C92 74 94 74 95.3 73.8c.7-.1.4-1.2-.3-1.1-.7.1-2 .3-2.2-.7-.1-.7-1.2-.4-1.1.3M90.5 83.9v-.3c0-.6-.9-.7-1.1-.1-.1.3-.4.6-.5.9-.1.4 0 .8.3 1 .3.3.7.4 1.1.5.5.1 1 .3 1.5.4.7.2 1-.9.3-1.1l-1.8-.6s-.3-.1-.3 0c0-.1.1-.2.2-.3.1-.2.2-.4.3-.7-.4 0-.7-.1-1.1-.1v.3c0 .8 1.1.8 1.1.1M85.4 89.9c0-.2-.1-.9-.1-.9.1-.1.7-.2.9-.3.8-.2 1.5-.3 2.3-.1.7.2 1-.9.3-1.1-1.2-.4-2.5-.1-3.6.3s-1 1.3-.8 2.3c0 .8 1.1.5 1-.2M66.8 72c-3.3 5.6-6.4 11.3-9.1 17.2-1.3 2.9-2.5 5.5-4.7 7.9-2 2.2-4.3 3.3-6.6 4.9-.6.4 0 1.3.6.9 4.5-3 8.1-5.5 10.5-10.5 3.3-6.8 6.4-13.5 10.3-19.9.3-.5-.6-1.1-1-.5"
      />
      <path
        className="prefix__st8"
        d="M71.4 70.7c-2.6 1.7-5 3.6-7.1 5.8-.5.5.3 1.3.8.8 2.1-2.1 4.4-4 6.9-5.6.6-.4 0-1.4-.6-1M82.2 78.7c-.5.6-.8 1.2-1.1 1.8-.1.3.1.6.4.7.3.1.6-.1.7-.4.2-.5.5-.9.8-1.3.4-.5-.4-1.3-.8-.8"
      />
      <path
        className="prefix__st8"
        d="M82.6 79.3c-.3.4-.5.8-.6 1.2-.2.7.8 1 1.1.3.1-.4.3-.7.5-1 .4-.5-.6-1.1-1-.5M85.8 71.7c-.1.5-.1 1.1.2 1.6.4.6 1.3 0 1-.6-.1-.2-.2-.4-.1-.7.2-.7-.9-.9-1.1-.3"
      />
      <path
        className="prefix__st8"
        d="M86.7 72c0 .5 0 .9.1 1.4.2.7 1.3.4 1.1-.3-.1-.4-.1-.7-.1-1.1.1-.7-1.1-.7-1.1 0M71.9 71.6c1.3-.7 2.6-1.2 4-1.6.7-.2.4-1.2-.3-1.1-1.5.4-2.9 1-4.3 1.7-.6.4 0 1.3.6 1"
      />
      <path
        className="prefix__st8"
        d="M72.5 71.2c.4-.4.9-.8 1.4-1.1.6-.4.1-1.3-.6-.9-.6.3-1.1.8-1.6 1.3-.5.4.3 1.2.8.7M91.4 64.4c0-.7-1.1-.7-1.1 0 0 .8 1 .6 1.5.6-.2-.1-.4-.3-.5-.4.1.4-.6.6-.9.8-.6.3-.1 1.2.6.9.9-.4 1.6-1 1.4-2 0-.2-.3-.4-.5-.4H91c.1.1.3.1.4.2-.1 0 0 .2 0 .3h-1.1c0 .7 1.1.7 1.1 0"
      />
      <path
        className="prefix__st8"
        d="M91.7 66.1c0-.1.1-.2.3-.3-.2-.3-.4-.6-.7-.9l-.9.9c-.5.5.3 1.3.8.8l.9-.9c.5-.4-.1-1.1-.7-.9-.5.2-.7.7-.8 1.2-.1.8 1.1.8 1.1.1M79.1 65.1c-.1 0-.1 0 0 0 .1.1.3.2.5.3 0 0 0-.1-.1-.1v.6c.1-.3.7-.6.9-.8.3-.3.5-.7.7-1 .4-.8.6-1.6.7-2.5.1-.7-1-.7-1.1 0-.1 1.1-.5 2.1-1.3 2.9-.4.4-1.3.8-.8 1.5.1.2.4.3.6.3h.1c.8-.3.5-1.3-.2-1.2"
      />
      <path
        className="prefix__st8"
        d="M77.9 62.7c0 2 2.6.8 3.4.1.6-.5-.2-1.2-.8-.8-.2.2-.5.4-.8.5-.1 0-.8.3-.8.2.1-.7-1-.7-1 0M71.6 84.8c-3.4-.8-6.8-1.9-9.7-3.8-4-2.7-5.2-6.1-5.6-10.6-.1-.7-1.2-.7-1.1 0 .4 4.3 1.3 7.7 4.8 10.5 3.2 2.6 7.3 4 11.3 4.9.7.2 1-.9.3-1M82.8 83.7c-.8 0-3.2-.4-2.8 1.2.1.5.7.7 1.1.8.8.2 1.6.2 2.4.2.2 0 .5-.2.5-.4.4-1.7-2.1-1.8-3.2-1.9-.6 0-1.3.1-1.3.9.1.8 1 1 1.7 1.1.7.1 1.7.2 2.3-.2.6-.3.6-1 .1-1.4-.5-.5-1.3-.6-1.9-.5-.5 0-.9.1-1.4.1-.3 0-.6 0-.8.2-.2.2-.2.6 0 .8.2.2.6.2.8 0 .6-.4-.2-1.2-.8-.8h.8v.8c-.1.1.5 0 .5 0 .2 0 .5 0 .7-.1.4 0 1.1-.1 1.4.4 0-.1 0-.3-.1-.4 0 .1-.9.1-1 .1-.2 0-.5-.1-.7-.1-.1 0-.2 0-.2-.1-.4.2-.5.3-.2.3.2.1 2.4.1 2.3.5.2-.1.4-.3.5-.4h-1.1c-.3 0-.9-.3-1.2-.2-.2-.1-.2-.1-.1.1h.5c.4 0 .7 0 1.1.1.8 0 .8-1.1.1-1.1"
      />
      <path
        className="prefix__st8"
        d="M83.4 85.4c4 1.5 8 3.1 11.8 4.9.6.3 1.2-.6.6-.9-4-1.9-8-3.5-12.1-5-.7-.3-1 .7-.3 1"
      />
      <path
        className="prefix__st8"
        d="M83.5 85.7c.6.2 1.1.4 1.7.5.7.2 1-.8.3-1.1-.7-.2-1.3-.4-2-.6-.7-.2-1 .8-.3 1.1 1.3.4 2.7.9 4 1.3.7.2 1-.8.3-1.1-1.3-.4-2.7-.9-4-1.3-.1.4-.2.7-.3 1.1.7.2 1.3.4 2 .6.1-.4.2-.7.3-1.1-.6-.2-1.1-.4-1.7-.5-.7-.2-1 .9-.3 1.1"
      />
      <path
        className="prefix__st8"
        d="M82.4 84.6c.7.6 1.5 1.1 2.3 1.4.5.2.9-.6.5-.9-.6-.5-1.2-.8-1.9-1.1-.1.4-.2.7-.3 1.1.5.1.8.2 1.2.5l.6-.9c-.4-.2-.8-.4-1.1-.6-.6-.3-1.1.4-.7.9.9 1.1 2.8 1.5 4.1 2 .7.3.9-.8.3-1.1-1.1-.5-2.8-.8-3.6-1.8-.2.3-.4.6-.7.9.4.2.8.4 1.1.6.7.3 1.1-.5.6-.9-.4-.3-.9-.5-1.4-.6-.7-.1-1 .8-.3 1.1.5.2 1 .5 1.4.8.2-.3.4-.6.5-.9-.7-.3-1.3-.7-1.8-1.1-.5-.6-1.3.2-.8.6"
      />
      <path
        className="prefix__st8"
        d="M82.5 83.5c-1-.2-3.2-.4-3 1.2 0-.2.1-.4.1-.5-.2.2-.3.5-.2.8.1.2.3.4.5.4s.3 0 .5-.2c.6-.4 0-1.4-.6-.9.2.1.4.3.5.4-.1.3-.1.4 0 .2 0-.1.1-.2.1-.3v-.1h.2c.4-.1.9 0 1.4.1.9.1 1.2-1 .5-1.1"
      />
      <path
        className="prefix__st8"
        d="M80.2 84c-1-.2-1.3.9-.6 1.5.6.5 1.8.5 2.5.6 1.3.2 2.6.4 3.8.6.7.1 1-1 .3-1.1-1.1-.2-2.1-.3-3.2-.5-.6-.1-2.4-.7-2.8-.3-.2 0-.4.1-.5.1h.2c.7.3 1-.8.3-.9"
      />
      <path
        className="prefix__st8"
        d="M83.7 85.4c2.2.4 4.1 1.7 6.1 2.7 1.8.8 3.6 1.6 5.5 2.3.7.2 1-.8.3-1.1-2.3-.8-4.5-1.8-6.7-2.8-1.6-.8-3.1-1.7-4.9-2.1-.7-.2-1 .8-.3 1"
      />
      <path
        className="prefix__st8"
        d="M88.2 87.5c.6.2 1.2.4 1.7.6.1-.4.2-.7.3-1.1-.4-.1-.8-.2-1.1-.3-.7-.2-1 .9-.3 1.1.7.2 1.3.4 2 .6.7.2 1-.9.3-1.1-.7-.2-1.3-.4-2-.6-.1.4-.2.7-.3 1.1.4.1.8.2 1.1.3.7.2 1-.8.3-1.1-.6-.2-1.2-.4-1.7-.6-.7-.2-1 .9-.3 1.1"
      />
    </g>
  </svg>
)

export default AlpamamaLogo

